import { useEffect, useState } from "react";
import ProfileImages from "../Modals/ProfileImagesModal";
import ModalBookedTicket from "../Modals/BookedTicketsModal";
import { SidebarWrapper } from "../Styles/Nav-Footer-Style";
import axios from "axios";
import { Profile_Image_URL, User_Details_URL } from "../API/Api";



function Sidebar({profileModalShow, setProfileModalShow, userImage, setUserImage}) {

    const [profileImgModalShow, setProfileImgModalShow] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [bookingListShow, setBookingListShow] = useState(false);


    const fetchUserDetails = () => {
        let loginStatus = localStorage.getItem("Login");

        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${User_Details_URL}?user=${user.user}`)
            .then(res => {
                setUserDetails(res.data.user);
            })
            .catch(err => {
                console.error(err);
            });
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);


    useEffect(() => {
        if (profileModalShow) {
            fetchUserDetails();
        }

        if (!profileModalShow) {
            setProfileImgModalShow(false);
            setBookingListShow(false);
        }
    }, [profileModalShow]);

    const handleProfileImgModal = () => {
        setProfileImgModalShow(!profileImgModalShow);
    };

    const toggleBookingLists = () => {
        setBookingListShow(!bookingListShow);
    };

    const handleLogout = () => {
        localStorage.setItem("Login", "false");
        localStorage.removeItem("UserData");
        localStorage.removeItem("location");
        setProfileModalShow(false);
        setUserDetails([]);
        setUserImage(null);
        window.location.reload();
    };

    return(
        <>
            <SidebarWrapper className={profileModalShow ? 'active' : ''}>
                <div className="close_btn" onClick={() => setProfileModalShow(false)}>
                    <a><i className="fa-solid fa-arrow-right-long"></i></a>
                </div>
                <div className="sidebar_inner">
                    <div className="profile_image_sec">
                        <div className="image_box">
                            <div className="box_inner">
                                <img src={`${Profile_Image_URL}/${userImage}`} alt="profile" />
                            </div>
                            <a className="edit_btn" onClick={handleProfileImgModal}><i className="fa-solid fa-pen"></i></a>
                        </div>

                        <h5>{userDetails.name}</h5>
                    </div>
                    <div className="user_details_sec">
                        <li>
                            <span>Phone :</span>
                            <p>{userDetails.phone}</p>
                        </li>
                        <li>
                            <span>Email :</span>
                            <p>{userDetails.email}</p>
                        </li>
                        <li>
                            <span>City :</span>
                            <p>{userDetails.city}</p>
                        </li>
                        <li>
                            <span>State :</span>
                            <p>{userDetails.state}</p>
                        </li>
                    </div>
                    <div className="booking_list_btn">
                        <a onClick={toggleBookingLists}>Your Booking Lists<i class="fa-solid fa-arrow-right-long"></i></a>
                    </div>
                    <div className="logout_btn">
                        <a onClick={handleLogout}><i className="fa-solid fa-right-from-bracket"></i>Logout</a>
                    </div>
                </div>
            </SidebarWrapper>

            <ProfileImages profileImgModalShow={profileImgModalShow} setProfileImgModalShow={setProfileImgModalShow} userImage={userImage} setUserImage={setUserImage} />
            <ModalBookedTicket bookingListShow={bookingListShow} setBookingListShow={setBookingListShow} />
        </>
    );
}


export default Sidebar;