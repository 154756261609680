import { styled } from "@mui/material";


export const Nav = styled('nav')`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px 100px;
    z-index: 999;
    transition: all 0.7s;

    .nav_inner {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;

        .logo {
            position: relative;
            display: flex;
            padding: 5px 0;

            img {
                position: relative;
                height: 80px;
                width: auto;
            }
        }

        .filter_box {
            position: relative;
            margin-left: auto;

            .input_btn {
                position: relative;

                a {
                    position: relative;
                    display: block;
                    align-items: center;
                    color: #FFF;
                    font-size: 20px;
                    cursor: pointer;
                    font-weight: 500;

                    i {
                        position: relative;
                        margin-right: 12px;
                    }

                    &.active {
                        color: #3FE44F;
                    }
                }
            }
        }

        .nav_items {
            position: relative;
            margin-left: 30px;
            display: flex;
            align-items: center;

            ul {
                position: relative;
                display: flex;
                align-items: center;

                li {
                    list-style: none;
                    margin: 0px 15px;

                    a {
                        position: relative;
                        text-decoration: none;
                        padding: 0px 15px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 20px;
                        font-weight: 500;
                        color: #FFF;
                        padding-bottom: 10px;
                        transition: all 0.4s;

                        &.active {
                            color: #FFFF6C;
                            transition: all 0.4s;
                        }

                        &:hover {
                            color: #FFFF6C;
                            transition: all 0.4s;
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            background: #FC6736;
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.4s;
                        }
                        &.active::before {
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.4s;
                        }

                        &:hover::before {
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.4s;
                        }
                    }
                }
            }

            .nav_btn {
                position: relative;
                margin-left: 80px;

                a {
                    position: relative;
                    margin-top: auto;
                    text-decoration: none;
                    font-family: 'Poppins', sans-serif;
                    font-size: 17px;
                    line-height: 1;
                    width: 200px;
                    height: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: #fff;
                    transition: 0.5s;
                    overflow: hidden;
                    cursor: pointer;

                    &:hover {
                        color: #FFFF6C;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #FC6736;
                        z-index: -1;
                        transition: 0.5s;
                    }

                    &:hover::before {
                        top: calc(100% - 2px);
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        top: calc(-100% + 2px);
                        left: 100%;
                        width: 100%;
                        height: 100%;
                        background: #FC6736;
                        z-index: -1;
                        transition: 0.5s;
                    }

                    &:hover::after {
                        left: 0;
                        transition-delay: 0.4s;
                    }


                    span {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-left: 2px solid #FC6736;
                        border-right: 2px solid #FC6736;
                        transform: scaleY(0);
                        transform-origin: top;
                        transition: 0.5s;
                    }

                    &:hover span {
                        transform: scaleY(1);
                        transition-delay: 0.7s;
                    }
                }
            }

            .nav_profile {
                position: relative;
                margin-left: 100px;

                .profile_btn {
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    img {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    p {
                        position: relative;
                        color: #FFF;
                        white-space: nowrap;
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: 15px;
                        font-size: 19px;
                        font-weight: 500;
                    }
                }
            }
        }

        .res_nav_btn {
            position: relative;
            display: none;

            a {
                position: relative;
                font-size: 22px;
                color: #FFF;
                cursor: pointer;

                i {
                    transform: rotate(0deg);
                    transition: all 0.6s;
                }

                &.active {
                    color: #FC6736;
                }

                &.active i {
                    transform: rotate(180deg);
                    transition: all 0.6s;
                }
            }
        }
    }

    &.NavBg {
        background: #FFF;
        border-bottom: 1px solid #E1E0EA;

        .nav_inner {

            .filter_box .input_btn a {
                color: #000;
            }

            .nav_items {

                ul {

                    li {

                        a {
                            color: #000;

                            &.active {
                                color: #666;
                            }

                            &:hover {
                                color: #666;
                            }
                        }
                    }
                }

                .nav_btn {
                    position: relative;
                    margin-left: 80px;

                    a {

                        &:hover {
                            color: #666;
                        }
                    }
                }

                .nav_profile {

                    .profile_btn {

                        p {
                            color: #111;
                        }
                    }
                }
            }

            .res_nav_btn {

                a {
                    color: #000;
                }
            }
        }
    }

    &.white {
        background: #FFF;
        box-shadow: 0 15px 25px rgba(0,0,0,0.3);
        border-bottom: none;
        transition: all 0.7s;

        .nav_inner {

            .filter_box .input_btn a {
                color: #000;
            }

            .nav_items {

                ul {

                    li {

                        a {
                            color: #000;

                            &.active {
                                color: #666;
                            }

                            &:hover {
                                color: #666;
                            }
                        }
                    }
                }

                .nav_btn {
                    position: relative;
                    margin-left: 80px;

                    a {

                        &:hover {
                            color: #666;
                        }
                    }
                }

                .nav_profile {

                    .profile_btn {

                        p {
                            color: #111;
                        }
                    }
                }
            }

            .res_nav_btn {

                a {
                    color: #000;
                }
            }
        }
    }

    @media (max-width: 1570px){
        
        .nav_inner .filter_box .input_box {
            top: 55px;
            left: 0;
            width: 550px;
            transform-origin: left;
        }

    }

    @media (max-width: 1250px){
        padding: 15px 70px;
    }

    @media (max-width: 1150px){

        .nav_inner .filter_box .input_box {
            top: -15px;
            right: 120%;
            left: inherit;
            width: 400px;
            transform-origin: right;
        }
        
        .nav_inner .nav_items {
            display: none;
        }
        
        .nav_inner .res_nav_btn {
            display: block;
            margin-left: 50px;
        }
    }

    @media (max-width: 991px){

        padding: 15px 50px;

        .nav_inner .logo img {
            height: 50px;
        }

        .nav_inner .filter_box .input_btn a {
            font-size: 16px;
        }

        .nav_inner .res_nav_btn a {
            font-size: 17px;

            &.active {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 768px) {
        .nav_inner .res_nav_btn {
            margin-left: 35px;
        }
    }

    @media (max-width: 575px) {
        padding: 15px 35px;
    }

    @media (max-width: 450px) {
        padding: 15px 25px;
    }
`;

export const NavSideWrapper = styled('div')`
    position: fixed;
    top: 120px;
    right: -120%;
    width: 330px;
    z-index: 1200;
    height: calc(100vh - 120px);
    background: #FFF;
    border-radius: 10px 0 0 0;
    padding: 35px;
    box-shadow: -5px 2px 10px 0px rgba(0,0,0,0.1);
    border-top: 1px solid #E1E0EA;
    transition: all 0.8s;

    &.active {
        right: 0;
        transition: all 0.8s;
    }

    .sidebar_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .profile_sec {
            position: relative;
            width: 100%;
            padding-bottom: 15px;
            border-bottom: 1px solid #E1E0EA;
            margin-bottom: 20px;

            .profile_btn {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                    position: relative;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                p {
                    position: relative;
                    width: calc(100% - 55px);
                    padding-left: 20px;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .sidebar_items {
            position: relative;
            width: 100%;
            margin-top: 15px;

            ul {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                li {
                    list-style: none;
                    margin: 6px 0;

                    a {
                        text-decoration: none;
                        color: #444;
                        font-size: 15px;

                        &.active {
                            color: #FC6736;
                        }
                    }
                }
            }
        }

        .auth_btn {
            position: relative;
            margin-top: auto;
            width: 100%;

            a {
                position: relative;
                width: 100%;
                height: 50px;
                background: #FC6736;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                cursor: pointer;
                color: #FFF;
                font-size: 15px;
                font-weight: 500;

                i {
                    position: relative;
                    font-size: 16px;
                    margin-right: 8px;
                }
            }
        }
    }

    @media (max-width: 991px) {
        top: 90px;
        height: calc(100vh - 90px);
    }

    @media (max-width: 575px) {
        padding: 30px 25px;
    }

`;

export const FooterWrapper = styled('footer')`
    position: relative;
    width: 100%;
    background: rgb(49, 48, 53);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background: #FFF;
    }

    .shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        svg {
            position: relative;
            transform: translateY(-60%);
        }

        @media (max-width: 1250px) {
            svg {
                position: relative;
                transform: translateY(-45%);
            }
        }

        @media (max-width: 875px) {
            overflow-x: hidden;
            svg {
                height: 250px;
                transform: translateY(-45%);
            }
        }
    }

    .footer {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 10;

        .footer_top {
            position: relative;
            width: 100%;
            padding: 50px 100px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;

            .newsettler_sec {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding-top: 25px;

                .left_part {
                    position: relative;

                    h5 {
                        position: relative;
                        font-family: 'Lemonada', cursive;
                        font-size: 20px;
                        color: #FFF;

                        span {
                            color: #FC6736;
                        }
                    }

                    form {
                        position: relative;
                        margin-top: 25px;
                        display: flex;
                        align-items: center;

                        input {
                            position: relative;
                            width: 500px;
                            height: 55px;
                            padding: 5px 25px;
                            border: none;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            background: rgba(255,255,255,0.2);
                            color: #FFF;
                            outline: none;
                            border-radius: 8px;
                        }

                        button {
                            position: relative;
                            margin-left: 25px;
                            width: 200px;
                            height: 53px;
                            border: none;
                            outline: none;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 18px;
                            font-weight: 500;
                            background: #FFC76C;
                            color: #000;
                            border-radius: 6px;
                            cursor: pointer;
                        }
                    }
                }

                .right_part {
                    position: relative;
                    display: flex;

                    .link_box {
                        position: relative;
                        margin-right: 60px;

                        &:last-of-type {
                            margin-right: 0;
                        }

                        a {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            color: rgba(255,255,255,0.6);
                            text-decoration: none;
                            text-align: center;
                            cursor: pointer;
                            transition: all 0.5s;

                            &:hover {
                                color: rgb(255,255,255);
                                transition: all 0.5s;
                            }

                            i {
                                position: relative;
                                height: 70px;
                                font-size: 68px;
                            }
                            
                            span {
                                position: relative;
                                margin-top: 15px;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                line-height: 1.4;
                            }
                        }

                        &:nth-child(2) a i {
                            font-size: 63px;
                            transform: rotate(-45deg);
                        }

                        &:nth-child(3) a i {
                            font-size: 60px;
                        }
                    }
                }
            }

            .footer_gap {
                position: relative;
                margin: 60px 0;
                width: 100%;

                .gap_image {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    z-index: 5;

                    .img_box {
                        position: relative;
                        padding: 0px 50px;
                        background: rgb(49, 48, 53);

                        img {
                            position: relative;
                            display: flex;
                            width: 75px;
                            height: auto;
                        }
                    }
                }

                .gap_line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .line {
                        position: relative;
                        width: 100%;
                        height: 2px;
                        background: rgba(255,255,255,0.2);
                    }
                }
            }

            .sec_items {
                position: relative;

                .items_inner {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    .item_box {
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        width: max-content;
                        margin-bottom: 40px;
                        
                        &.links_box {
                            width: 10%;
                        }

                        p {
                            position: relative;
                            font-size: 18px;
                            font-family: 'Poppins', sans-serif;
                            color: #FFF;
                        }

                        h5 {
                            position: relative;
                            width: max-content;
                            padding-bottom: 12px;
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 18px;
                            color: #FFF;

                            &::before {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 70%;
                                height: 3px;
                                background: #FC6736;
                                border-radius: 20px;
                            }
                        }

                        ul {
                            position: relative;
                            margin-top: 15px;
                            display: flex;
                            flex-direction: column;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 7px 0;

                                a {
                                    position: relative;
                                    text-decoration: none;
                                    font-size: 16px;
                                    font-family: 'Poppins', sans-serif;
                                    color: rgba(255,255,255,0.6);
                                    transition: all 0.5s;
                                    cursor: pointer;

                                    i {
                                        margin-right: 8px;
                                        font-size: 15px;
                                        transition: all 0.5s;
                                    }

                                    &:hover {
                                        color: rgb(255,255,255);
                                        transition: all 0.5s;

                                        i {
                                            margin-right: 15px;
                                            transition: all 0.5s;
                                        }
                                    }
                                }
                            }
                        }

                        .social_media {
                            position: relative;
                            margin-top: 25px;
                            display: flex;
                            align-items: center;

                            li {
                                list-style: none;
                                margin-right: 20px;

                                &:last-of-type {
                                    margin-right: 0;
                                }

                                a {
                                    position: relative;
                                    width: 55px;
                                    height: 55px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background: rgba(255,255,255,0.3);
                                    text-decoration: none;
                                    font-size: 22px;
                                    border-radius: 50%;
                                    color: rgb(49, 48, 53);
                                    transition: all 0.5s;

                                    &:hover {
                                        background: rgb(255,255,255);
                                        transition: all 0.5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 1800px) {
                .sec_items .items_inner .item_box.links_box {
                    width: 15%;
                }
            }

            @media (max-width: 1499px) {

                .newsettler_sec {
                    flex-direction: column;
                }

                .newsettler_sec .right_part {
                    margin-top: 50px;
                }

                .sec_items .items_inner .item_box.links_box {
                    width: 20%;
                }
            }

            @media (max-width: 1250px) {
                padding: 50px 70px;
                padding-bottom: 10px;

                .sec_items .items_inner {
                    justify-content: space-between;
                }

                .sec_items .items_inner .item_box {
                    &:first-of-type {
                        width: 100%;

                        p br {
                            display: none;
                        }
                    }

                    &.links_box{
                        width: 33.33%;
                    }
                }
            }

            @media (max-width: 991px) {
                padding: 50px;
                padding-bottom: 10px;

                .newsettler_sec .left_part form {
                    width: 100%;
                    justify-content: space-between;

                    input {
                        width: calc(100% - 205px);
                    }

                    button {
                        width: 180px;
                        margin-left: 0;
                    }
                }
            }

            @media (max-width: 768px) {
                .newsettler_sec .left_part h5 {
                    font-size: 17px;
                    line-height: 1.5;
                }

                .newsettler_sec .right_part .link_box a i {
                    height: 55px;
                    font-size: 50px;
                }

                .newsettler_sec .right_part .link_box:nth-child(2) a i {
                    font-size: 45px;
                }

                .newsettler_sec .right_part .link_box:nth-child(3) a i {
                    font-size: 49px;
                }

                .footer_gap .gap_image .img_box {
                    padding: 0px 35px;

                    img {
                        width: 60px;
                    }
                }
            }

            @media (max-width: 575px) {
                padding: 50px 35px;
                padding-bottom: 10px;

                .newsettler_sec .left_part form {
                    flex-direction: column;

                    input {
                        width: 100%;
                        margin-bottom: 25px;
                    }

                    button {
                        width: 180px;
                        margin-left: 0;
                    }
                }

                .newsettler_sec .right_part .link_box {
                    margin-right: 35px;
                }

                .newsettler_sec .right_part .link_box a span {
                    font-size: 14px;
                }

                .sec_items .items_inner .item_box {

                    &.links_box{
                        width: 50%;
                    }
                }

                .sec_items .items_inner .item_box .social_media li a {
                    width: 45px;
                    height: 45px;
                    font-size: 17px;
                }
            }

            @media (max-width: 450px) {
                padding: 50px 25px;
                padding-bottom: 00px;

                .newsettler_sec .left_part form {
                    input {
                        margin-bottom: 20px;
                    }

                    button {
                        width: 100%;
                    }
                }

                .newsettler_sec .right_part .link_box a i {
                    height: 50px;
                    font-size: 45px;
                }

                .newsettler_sec .right_part .link_box:nth-child(2) a i {
                    font-size: 40px;
                }

                .newsettler_sec .right_part .link_box:nth-child(3) a i {
                    font-size: 43px;
                }

                .footer_gap .gap_image .img_box {
                    padding: 0px 20px;

                    img {
                        width: 45px;
                    }
                }

                .sec_items .items_inner .item_box p {
                    font-size: 16px;
                    line-height: 1.5;
                }

                .sec_items .items_inner .item_box {

                    &.links_box{
                        width: 100%;
                    }
                }
            }
        }

        .footer_bottom {
            position: relative;
            width: 100%;
            padding: 25px 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid rgba(255,255,255,0.2);

            p {
                position: relative;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                color: rgba(255,255,255,0.6);

                span {
                    margin: 0px 3px;
                    font-size: 19px;
                    color: #FC6736;
                    font-family: 'Oleo Script', cursive;
                }
            }

            .dot {
                position: relative;
                margin-left: 20px;
                margin-right: 15px;
                display: flex;
                align-items: center;

                i {
                    font-size: 5px;
                    color: rgba(255,255,255,0.3);
                }
            }

            .links {
                position: relative;
                display: flex;
                align-items: center;

                a {
                    position: relative;
                    text-decoration: none;
                    cursor: pointer;
                    font-family: 'Ubuntu', sans-serif;
                    color: rgba(255,255,255,0.6);
                    font-size: 17px;
                    line-height: 1;
                    transition: all 0.5s;

                    &:first-of-type {
                        padding-right: 20px;
                        border-right: 1px solid rgba(255,255,255,0.3);
                    }

                    &:last-of-type {
                        border-left: 1px solid rgba(255,255,255,0.3);
                        padding-left: 20px;
                    }

                    &:hover {
                        color: #FFF;
                        transition: all 0.5s;
                    }
                }
            }

            @media (max-width: 1250px) {
                padding: 25px 70px;
                flex-direction: column;
                text-align: center;

                .dot {
                    display: none;
                }

                .links {
                    margin-top: 15px;
                }
            }

            @media (max-width: 575px) {
                padding: 25px 35px;
            }

            @media (max-width: 450px) {
                padding: 25px;
            }
        }
    }
`;

export const SidebarWrapper = styled('div')`
    position: fixed;
    top: 120px;
    right: -120%;
    width: 400px;
    height: calc(100vh - 120px);
    background: #3FE44F;
    z-index: 1200;
    border-radius: 10px 0 0 0;
    padding: 35px;
    padding-top: 50px;
    padding-bottom: 25px;
    transition: all 0.9s;

    &.active {
        right: 0;
        transition: all 0.7s;
    }

    .close_btn {
        position: absolute;
        top: 25px;
        right: 30px;
        display: none;

        a {
            position: relative;
            font-size: 22px;
            cursor: pointer;
            color: #FFF;
        }
    }

    .sidebar_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .profile_image_sec {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255,255,255,0.5);

            .image_box {
                position: relative;
                width: 160px;
                height: 160px;
                border: 2px solid #FFF;
                border-radius: 50%;
                padding: 6px;

                .box_inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    border: 1px solid #FFF;
                    border-radius: 50%;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                        overflow: hidden;
                    }
                }

                a {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 45px;
                    height: 45px;
                    background: #02C0FF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #FFF;
                    cursor: pointer;
                    font-size: 16px;
                    box-shadow: 4px 4px 10px rgba(25,25,25,0.3),
                                -2px -2px 6px rgba(60,60,60,0.5);
                }
            }

            h5 {
                position: relative;
                margin-top: 30px;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                line-height: 1;
                color: #FFF;
            }
        }

        .user_details_sec {
            position: relative;
            width: 100%;
            padding: 15px 15px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid rgba(255,255,255,0.5);

            li {
                position: relative;
                width: 100%;
                list-style: none;
                display: flex;
                margin: 6px 0;

                span {
                    position: relative;
                    font-size: 17px;
                    font-weight: 600;
                    color: #FFF;
                    width: 75px;
                }

                p {
                    position: relative;
                    width: calc(100% - 75px);
                    font-size: 17px;
                    color: #FFF;
                    line-height: 1.5;
                    word-break: break-all;
                }
            }
        }

        .booking_list_btn {
            position: relative;
            padding: 0px 15px;
            padding-top: 20px;

            a {
                position: relative;
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                color: #FFF;
                cursor: pointer;
                display: flex;
                align-items: center;

                i {
                    position: relative;
                    margin-left: auto;
                }
            }
        }

        .logout_btn {
            position: relative;
            margin-top: auto;
            width: 100%;

            a {
                position: relative;
                width: 100%;
                height: 65px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFF;
                border-radius: 6px;
                cursor: pointer;
                font-size: 18px;
                font-weight: 500;
                color: #3FE44F;

                i {
                    position: relative;
                    margin-right: 10px;
                    font-size: 20px;
                }
            }
        }
    }

    @media (max-width: 1150px) {
        width: 330px;
        padding: 25px;
        padding-top: 50px;
        padding-bottom: 25px;

        .close_btn {
            display: block;
        }

        .sidebar_inner .logout_btn {
            display: none;
        }
    }

    @media (max-width: 991px) {
        top: 90px;
        height: calc(100vh - 90px);
    }
`;