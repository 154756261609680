import { useEffect, useState } from "react";
import { BookedTicketsBox } from "../Styles/Modal-Style";
import QRCode from 'qrcode.react';
import axios from "axios";
import { Fetch_Tickets_Details_URL, Fetch_Tickets_URL, Poster_Image_URL } from "../API/Api";



function ModalBookedTicket({bookingListShow, setBookingListShow}) {

    const [selectedBgColor, setSelectedBgColor] = useState('');
    const [ticketDetailsShow, setTicketDetailsShow] = useState(false);
    const [priceDetailsShow, setPriceDetailsShow] = useState(false);
    const [ticketCount, setTicketCount] = useState('');
    const [qrData, setqrData] = useState({ booking_id: '', user: '' });
    const [tickets, setTickets] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState([]);


    useEffect(() => {

        let loginStatus = localStorage.getItem("Login");

        if (loginStatus == "true") {
            let user = JSON.parse(localStorage.getItem("UserData"));
            axios.get(`${Fetch_Tickets_URL}?name=${user.user}`)
            .then(res => {
                console.log(res);
                setTicketCount(res.data.count);
                setTickets(res.data.tickets);
            })
            .catch(err => {
                // console.log(err);
                setTickets([]);
            })
        }

    }, [bookingListShow]);

    const handleTicketClick = (e, id) => {

        let user = JSON.parse(localStorage.getItem("UserData"));
        const ticketElement = e.currentTarget;
        const backgroundColor = window.getComputedStyle(ticketElement).backgroundColor;

        axios.get(`${Fetch_Tickets_Details_URL}?name=${user.user}&&bookingId=${id}`)
        .then(res => {
            console.log(res.data.ticket);
            setqrData({
                booking_id: res.data.ticket.booking_id,
                user: res.data.ticket.username
            });
            setSelectedTicket(res.data.ticket)
            setSelectedBgColor(backgroundColor);
            setTicketDetailsShow(!ticketDetailsShow);
        })
        .catch(err => {
            console.log(err);
            setTicketDetailsShow(false);
        })
    };


    const closeBookingLists = () => {
        setBookingListShow(false);
    };

    const toggleTicketDetails = () => {
        setTicketDetailsShow(!ticketDetailsShow);
    };

    const togglePriceDetails = () => {
        setPriceDetailsShow(!priceDetailsShow);
    };


    return(
        <>
            <BookedTicketsBox className={bookingListShow ? 'active' : ''}>
                <div className="close">
                    <a onClick={closeBookingLists}><i className="fa-solid fa-arrow-right-long"></i></a>
                </div>
                <div className="wrapper_inner">
                    <div className="ticket_box_sec">
                        {
                            ticketCount > 0 ? 

                            tickets && tickets.map((ticket, index) => 
                            <div key={index} className="ticket" onClick={(e) => handleTicketClick(e, ticket.booking_id)}>
                                <div className="left_part">
                                    <div class="cuts">
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                    </div>

                                    <div class="content">
                                        <div class="content_inner">
                                            <h4>{ticket.movie}</h4>
                                            <div className="date_time">
                                                <p>{ticket.date}</p>
                                                <span><i className="fa-solid fa-circle"></i></span>
                                                <p>{ticket.time}</p>
                                            </div>
                                            <div className="section">
                                                <span>Section :</span>
                                                <p>{ticket.class}</p>
                                            </div>
                                            <div className="seats">
                                                <span>Seats :</span>
                                                <p>{ticket.seats}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right_part">
                                    <div class="cuts">
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                        <div class="cut"></div>
                                    </div>
                                    <div class="number"><p>{ticket.booking_id}</p></div>
                                </div>

                                <div class="circles">
                                    <div class="circle"></div>
                                    <div class="circle"></div>
                                </div>
                            </div>)
                            :
                            <div className="empty_message">
                                <p>You don't have any bookings.</p>
                            </div>
                        }
                    </div>
                </div>

                <div className={`ticket_details_popup ${ticketDetailsShow ? 'active' : ''}`}>
                    <div className="popup_inner">
                        <div className="close_btn">
                            <a onClick={toggleTicketDetails}><i className="fa-solid fa-angle-down"></i></a>
                        </div>
                        <div className="ticket_outer">
                            <div className="ticket_box" style={{ backgroundColor: selectedBgColor }}>
                                <div className="top_part">
                                    <div className="dots">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>

                                    <div className="top_items">
                                        <div className="poster_img">
                                            <img src={`${Poster_Image_URL}/${selectedTicket.poster_image}`} alt="poster" />
                                        </div>
                                        <h4>{selectedTicket.movie}</h4>
                                        <div className="date_time">
                                            <p>{selectedTicket.day}, {selectedTicket.date}</p>
                                            <span>|</span>
                                            <p>{selectedTicket.time}</p>
                                        </div>
                                        <h5>{selectedTicket.theater}</h5>
                                    </div>

                                    <div className="circles">
                                        <div className="circle"></div>
                                        <div className="circle"></div>
                                    </div>
                                </div>
                                <div className="bottom_part">
                                    <div className="bottom_items">
                                        <div className="id_sec">
                                            <span>Booking ID :</span>
                                            <p>{selectedTicket.booking_id}</p>
                                        </div>
                                        <div className="seats_sec">
                                            <span>{selectedTicket.class}</span>
                                            <i className="fa-solid fa-circle"></i>
                                            <p>{selectedTicket.seats}</p>
                                        </div>
                                        <div className="qrcode_sec">
                                            <QRCode value={JSON.stringify(qrData)} bgColor="transparent" />
                                        </div>
                                    </div>
                                </div>
                                <div className="price_part">
                                    <div className="dots">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                    
                                    <div className="price_items">
                                        <div className="item_inner">
                                            <span>Total Amount</span>
                                            <p>{(Number(selectedTicket.ticket_price) + Number(selectedTicket.base_convenience) + Number(selectedTicket.gst)).toFixed(2)}</p>
                                            <a className={priceDetailsShow ? 'active' : ''} onClick={togglePriceDetails}><i className="fa-solid fa-angle-down"></i></a>
                                        </div>
                                        <div className={`inner_details ${priceDetailsShow ? 'active' : ''}`}>
                                            <div className="details_box">
                                                <div className="details">
                                                    <div className="details_line">
                                                        <span>Ticket Price :</span>
                                                        <p>{selectedTicket.ticket_price}</p>
                                                    </div>
                                                    <div className="details_line">
                                                        <span>Convenience Fee :</span>
                                                        <p>{(Number(selectedTicket.base_convenience) + Number(selectedTicket.gst)).toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BookedTicketsBox>
        </>
    );
}


export default ModalBookedTicket;