import Banner from "../Components/Banner";
import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { PageWrapper } from "../Styles/Home-Style";
import axios from "axios";
import { Fetch_All_Shows_URL, Poster_Image_URL } from "../API/Api";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../Layouts/Data-Context";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';



function Home() {

    const {selectedCity} = useContext(DataContext);

    const [todayCount, setTodayCount] = useState(false);
    const [weekCount, setWeekCount] = useState(false);
    const [upcomingCount, setUpcomingCount] = useState(false);

    const [todayShows, setTodayShows] = useState([]);
    const [weekShows, setWeekShows] = useState([]);
    const [upcomingShows, setUpcomingShows] = useState([]);
    const navigate = useNavigate();



    useEffect(() => {

        if(selectedCity) {

            axios.get(`${Fetch_All_Shows_URL}?location=${selectedCity}`)
            .then(res => {
                // console.log(res);
                if(res.data.today_show_count > 0){
                    setTodayCount(true);
                    setTodayShows(res.data.today_shows);
                } else {
                    setTodayCount(false);
                    setTodayShows([]);
                }

                if(res.data.week_show_count > 0){
                    setWeekCount(true);
                    setWeekShows(res.data.week_shows);
                } else {
                    setWeekCount(false);
                    setWeekShows([]);
                }

                if(res.data.upcoming_show_count > 0){
                    setUpcomingCount(true);
                    setUpcomingShows(res.data.upcoming_shows);
                } else {
                    setUpcomingCount(false);
                    setUpcomingShows([]);
                }
            })
            .catch(err => {
                // console.log(err);
            })

        } else {
            axios.get(Fetch_All_Shows_URL)
            .then(res => {
                // console.log(res);
                if(res.data.today_show_count > 0){
                    setTodayCount(true);
                    setTodayShows(res.data.today_shows);
                } else {
                    setTodayCount(false);
                    setTodayShows([]);
                }
    
                if(res.data.week_show_count > 0){
                    setWeekCount(true);
                    setWeekShows(res.data.week_shows);
                } else {
                    setWeekCount(false);
                    setWeekShows([]);
                }
    
                if(res.data.upcoming_show_count > 0){
                    setUpcomingCount(true);
                    setUpcomingShows(res.data.upcoming_shows);
                } else {
                    setUpcomingCount(false);
                    setUpcomingShows([]);
                }
            })
            .catch(err => {
                // console.log(err);
            })
        }

    }, [selectedCity]);

    
    const getJoinedTypes = (types) => {
        return types.split(',').map(type => type.trim()).join(' / ');
    };

    const handleRedirect = (movieName) => {
        localStorage.setItem("Movie_name", movieName);
        navigate(`/movie?movie=${encodeURIComponent(movieName)}`);
    };


    return (
        <>
            <PageWrapper>

                {/* Banner  */}
                <Banner />

                {/* exclusive movies */}
                <div className="exclusive_movies">
                    <div className="sec_content">
                        <div className="sec_head">
                            <h2>
                                <span>Now</span>
                                <span>Showing</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(20 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        <div className="sec_items">
                            {
                                todayCount ? 
                                <Swiper
                                    slidesPerView={'auto'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                >
                                    {
                                        todayShows && todayShows.map((todayShow, index) => 
                                            <SwiperSlide key={index}>
                                                <div className="movie_box" onClick={() => handleRedirect(todayShow.movie_name)}>
                                                    <div className="box_inner">
                                                        <div className="poster_box">
                                                            <div className="poster_inner">
                                                                <div className="img_box"><img src={`${Poster_Image_URL}/${todayShow.poster_image}`} alt="poster" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="movie_info">
                                                            <h4>{todayShow.movie_name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                                :
                                <div className="no_data_message">
                                    <h3>There are no Shows for today</h3>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {/* All movies */}
                <div className="exclusive_movies middle_sec">
                    <div className="sec_content">
                        <div className="sec_head">
                            <h2>
                                <span>This</span>
                                <span>Week</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(20 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        <div className="sec_items">
                            {
                                weekCount ?
                                <Swiper
                                    slidesPerView={'auto'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                >
                                    {
                                        weekShows && weekShows.map((weekShow, index) => 
                                            <SwiperSlide key={index}>
                                                <div className="movie_box" onClick={() => handleRedirect(weekShow.movie_name)}>
                                                    <div className="box_inner">
                                                        <div className="poster_box">
                                                            <div className="poster_inner">
                                                                <div className="img_box"><img src={`${Poster_Image_URL}/${weekShow.poster_image}`} alt="poster" /></div>
                                                            </div>
                                                        </div>
                                                        <div className="movie_info">
                                                            <h4>{weekShow.movie_name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                                :
                                <div className="no_data_message">
                                    <h3>There are no Shows for this week</h3>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="sec_wave_top">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#FFFFE2" fill-opacity="1" d="M0,192L17.1,170.7C34.3,149,69,107,103,90.7C137.1,75,171,85,206,106.7C240,128,274,160,309,170.7C342.9,181,377,171,411,181.3C445.7,192,480,224,514,202.7C548.6,181,583,107,617,90.7C651.4,75,686,117,720,117.3C754.3,117,789,75,823,69.3C857.1,64,891,96,926,112C960,128,994,128,1029,144C1062.9,160,1097,192,1131,218.7C1165.7,245,1200,267,1234,250.7C1268.6,235,1303,181,1337,154.7C1371.4,128,1406,128,1423,128L1440,128L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path>
                        </svg>
                    </div>
                    <div className="sec_wave_bottom">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#FFFFE2" fill-opacity="1" d="M0,96L17.1,106.7C34.3,117,69,139,103,170.7C137.1,203,171,245,206,218.7C240,192,274,96,309,69.3C342.9,43,377,85,411,128C445.7,171,480,213,514,218.7C548.6,224,583,192,617,176C651.4,160,686,160,720,133.3C754.3,107,789,53,823,74.7C857.1,96,891,192,926,202.7C960,213,994,139,1029,122.7C1062.9,107,1097,149,1131,160C1165.7,171,1200,149,1234,149.3C1268.6,149,1303,171,1337,170.7C1371.4,171,1406,149,1423,138.7L1440,128L1440,0L1422.9,0C1405.7,0,1371,0,1337,0C1302.9,0,1269,0,1234,0C1200,0,1166,0,1131,0C1097.1,0,1063,0,1029,0C994.3,0,960,0,926,0C891.4,0,857,0,823,0C788.6,0,754,0,720,0C685.7,0,651,0,617,0C582.9,0,549,0,514,0C480,0,446,0,411,0C377.1,0,343,0,309,0C274.3,0,240,0,206,0C171.4,0,137,0,103,0C68.6,0,34,0,17,0L0,0Z"></path>
                        </svg>
                    </div>
                </div>

                {/* upcoming movies */}
                <div className="exclusive_movies">
                    <div className="sec_content">
                        <div className="sec_head">
                            <h2>
                                <span>Upcoming</span>
                                <span>Movies</span>
                                <svg id="sw-js-blob-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
                                            <stop id="stop1" stopColor="#FC6736" offset="0%"></stop>
                                            <stop id="stop2" stopColor="rgba(251, 168, 31, 1)" offset="100%"></stop>
                                        </linearGradient>
                                    </defs>
                                    <path fill="url(#sw-gradient)" d="M9.9,-15C16.8,-13.2,28.9,-18.5,30.4,-17.1C31.8,-15.8,22.6,-7.9,17.2,-3.1C11.8,1.6,10.2,3.3,9.6,6.1C8.9,8.9,9.3,12.8,7.9,15.5C6.5,18.2,3.2,19.5,-0.2,20C-3.7,20.4,-7.4,19.8,-9.3,17.4C-11.2,15,-11.3,10.8,-13.9,7.6C-16.5,4.4,-21.6,2.2,-22,-0.2C-22.4,-2.6,-18.1,-5.3,-15.1,-7.8C-12.1,-10.4,-10.5,-12.8,-8.2,-17.2C-5.9,-21.5,-2.9,-27.8,-0.7,-26.6C1.5,-25.4,3.1,-16.7,9.9,-15Z" width="100%" height="100%" transform="translate(20 50)" strokeWidth="0"></path>
                                </svg>
                            </h2>
                        </div>
                        <div className="sec_items">
                            {
                                upcomingCount ? 
                                <Swiper
                                    slidesPerView={'auto'}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                >
                                    {
                                        upcomingShows && upcomingShows.map((upcomingShow, index) => 
                                            <SwiperSlide key={index}>
                                                <div className="movie_box">
                                                    <div className="box_inner">
                                                        <div className="poster_box">
                                                            <div className="poster_inner">
                                                                <div className="img_box"><img src={`${Poster_Image_URL}/${upcomingShow.poster_image}`} alt="poster" /></div>
                                                                <div className="box_info">
                                                                    <h4>{upcomingShow.name}</h4>
                                                                    <p>{getJoinedTypes(upcomingShow.types)}</p>
                                                                    <a onClick={() => handleRedirect(upcomingShow.name)}><span><i className="fa-solid fa-circle-info details"></i> See Details</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="movie_info">
                                                            <h4>{upcomingShow.name}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                                :
                                <div className="no_data_message">
                                    <h3>No upcoming movies for now</h3>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </PageWrapper>
        </>
    );
}


export default Home;