import axios from "axios";
import { useEffect, useState } from "react";
import { SuccessWrapper } from "../Styles/Success-Style";
import { useNavigate } from "react-router-dom";



function PaymentSuccess() {

    const [countdown, setCountdown] = useState(5);
    const navigate = useNavigate();


    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            navigate('/home'); // Redirect to the home page
        }

        return () => clearInterval(interval);
    }, [countdown, navigate])

    return(
        <>
            <SuccessWrapper>
                <div className="wrapper_inner">
                    <div className="page_icon">
                        <img src="images/Thank-you.gif" alt="icon" />
                    </div>
                    <div className="page_content">
                        <h3>Thank You</h3>
                        <h5>Your booking is comfirmed.</h5>
                        <p>Redirecting to home page in <span>{countdown} second{countdown > 1 && 's'}</span></p>
                    </div>
                </div>
            </SuccessWrapper>
        </>
    );
}


export default PaymentSuccess;