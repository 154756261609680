import { useEffect, useRef, useState } from "react";
import { InfoWrapper } from "../Styles/Modal-Style";
import axios from "axios";
import { Fetch_Booked_Seats_URL, Theater_Dates_URL, Theater_Shows_URL } from "../API/Api";
import { useNavigate } from "react-router-dom";



function Cinemainfo({ showCinemaInfo, setShowCinemaInfo, theaterInfo, handleCinemaInfo, setShowDetails, setBookedSeats, setBookingModalShow }) {
    
    const [showButtons, setShowButtons] = useState(false);
    const [count, setCount] = useState(false);
    const [dates, setDates] = useState([]);
    const [shows, setShows] = useState([]);
    const [showCount, setShowCount] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedFormattedDate, setSelectedFormattedDate] = useState('');
    const datePanelRef = useRef(null);
    const navigate = useNavigate();

    function closeInfoScreen() {
        handleCinemaInfo();
    }

    const scrollLeft = () => {
        datePanelRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    };

    const scrollRight = () => {
        datePanelRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    };

    useEffect(() => {
        axios.get(`${Theater_Dates_URL}?name=${theaterInfo.name}`)
            .then(res => {
                console.log(res.data);

                if (res.data.count > 0) {
                    const formattedDates = res.data.dates.map(date => {
                        const [day, month] = date.date.split(',')[0].split(' ');
                        return {
                            date: date.date, // Ensure date is a string
                            formatted_date: `${day} ${month}`
                        };
                    });
                    setSelectedDate(formattedDates[0].date);
                    setSelectedFormattedDate(formattedDates[0].formatted_date);
                    setCount(true);
                    setShowCount(true);  
                    setDates(formattedDates);
                } else {
                    setCount(false);
                    setDates([]);
                    setShowCount(false);   
                }
            })
            .catch(err => {
                console.log(err);
                setCount(false);
                setShowCount(false);   
                setDates([]);
            }); 
    }, [theaterInfo.name]);

    useEffect(() => {
        const checkOverflow = () => {
            if (datePanelRef.current) {
                setShowButtons(datePanelRef.current.scrollWidth > datePanelRef.current.clientWidth);
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [dates]);

    useEffect(() => {
        if (selectedDate) {
            axios.get(`${Theater_Shows_URL}?date=${encodeURIComponent(selectedDate)}&name=${encodeURIComponent(theaterInfo.name)}`)
                .then(res => {
                    console.log(res.data);
                    if(res.data.counts > 0){
                        setShowCount(true);
                        setShows(res.data.shows);
                    } else {
                        setShowCount(false);
                        setShows([]);
                    }
                })
                .catch(err => {
                    // console.log(err);
                    setShowCount(false);
                    setShows([]);
                });
        }
    }, [selectedDate]);

    const handleDateClick = (formattedDate, date) => {
        setSelectedDate(date);
        setSelectedFormattedDate(formattedDate);
    };

    const handleRedirect = (movieName, language, screen, screen_id, time, date) => {

        const inputs = {
            theater: theaterInfo.name,
            screen: screen,
            screen_id: screen_id,
            movie_name: movieName,
            date: date,
            time: time
        };

        const showDetails = {
            theaterName: theaterInfo.name,
            screen: screen,
            screenId: screen_id,
            movieName: movieName,
            language: language,
            date: date,
            time: time
        };

        axios.post(Fetch_Booked_Seats_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            localStorage.setItem("Movie_name", movieName);
            setShowDetails(showDetails);
            setBookedSeats(res.data.booked);
            setBookingModalShow(true);
            setShowCinemaInfo(false);
        })
        .catch(err => {
            console.log(err);
        })

    };


    return (
        <>
            <InfoWrapper className={showCinemaInfo ? 'active' : ''}>
                <div className={`info_modal ${showCinemaInfo ? 'active' : ''}`}>
                    <div className="modal_inner">
                        <div className="top_part">
                            <h3>{theaterInfo.name}</h3>
                            <span onClick={closeInfoScreen}><i className="fa-solid fa-xmark"></i></span>
                        </div>

                        {count ? (
                            <div className="date_panel_wrapper">
                                <div className="arrow_btn left">
                                    {showButtons && <a className="scroll_btn" onClick={scrollLeft}><i className="fa-solid fa-arrow-left-long"></i></a>}
                                </div>
                                <div className="date_panel" ref={datePanelRef}>
                                    {dates.map((date, index) => (
                                        <label key={index} onClick={() => handleDateClick(date.formatted_date, date.date)} className={date.formatted_date === selectedFormattedDate ? 'selected' : ''}>
                                            <li>
                                                <span></span>
                                                <span></span>
                                                <p>{date.formatted_date}</p>
                                            </li>
                                        </label>
                                    ))}
                                </div>
                                <div className="arrow_btn right">
                                    {showButtons && <a className="scroll_btn" onClick={scrollRight}><i className="fa-solid fa-arrow-right-long"></i></a>}
                                </div>
                            </div>
                        ) : (
                            <div className="no_data_message">
                                <h3>No Movies in This Theater</h3>
                            </div>
                        )}

                        {
                            showCount ? (
                                <div className="movie_info_box">
                                    <div className="infobox_inner">
                                        {
                                            shows && shows.map((show, index) => 
                                                <div className="info_box" key={index}>
                                                    <div className="movie_name">
                                                        <p>{show.movie_name}</p>
                                                    </div>
                                                    <div className="movie_times">
                                                        {
                                                            show.timings && show.timings.map((timing, i) => 
                                                                <li>
                                                                    <a onClick={() => handleRedirect(show.movie_name, timing.language, timing.screen, timing.screen_id, timing.time, timing.date)} key={i}>{timing.time}</a>
                                                                    <span><p>{timing.language}</p></span>
                                                                </li>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ) : ( count &&
                                <div className="no_movie_message">
                                    <h3>No Movies Available on this day</h3>
                                </div>
                            )
                        }
                    </div>
                </div>
            </InfoWrapper>
        </>
    );
}

export default Cinemainfo;
